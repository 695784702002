export const formatAllowedLinks = (text, allowAllDomains = false) => {

  const allowedDomains = [
    'growdiaries.com', 
    'facebook.com',
    'twitter.com',
    'instagram.com',
    'linkedin.com',
    'pinterest.com',
    'reddit.com',
    'www.youtube.com',
    'm.youtube.com',
    'youtube.com',
    'vimeo.com',
    'dailymotion.com',
    'medium.com',
    'wikipedia.org',
  ];
  let domainPattern = allowedDomains.join('|').replace(/\./g, '\\.');

  if (allowAllDomains) {
    domainPattern = '[^\\s]+'; 
  }

  const pattern = new RegExp(`\\b(?:https?:\\/\\/)?(?:${domainPattern})\\S*`, 'gi');

  return text.replace(pattern, (url) => {

    if (!/^https?:\/\//.test(url)) {
      url = 'http://' + url;
    }

    const urlObj = new URL(url);
    const host = urlObj.host;

    if (allowAllDomains || allowedDomains.includes(host)) {
      const displayUrl = host + urlObj.pathname; 
      return `<a href="${url}">${displayUrl}</a>`;
    }

    return url; 
  });
}
  

export const formatUserMentions = (text) => {
  if(text && text.length){
    return text.replace(/@([a-zA-Z0-9_]+)/g, '<a href="/grower/$1">@$1</a>');
  }else{
    return '';
  }
}

export const formatCommonText = (text) => {
  text = formatAllowedLinks(text);
  text = formatUserMentions(text);
  return text;
}

export const formatCroppedText = (text, limit) => {
  if(text && text.length > limit){
    return text.substring(0, limit) + '...';
  }else{
    return text;
  }
}